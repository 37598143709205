var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { default as VitruvianMan } from '../icons/vitruvian_man.svg';
function boxScale(weight) {
    return Math.min(Math.cbrt(weight) / 10, 1);
}
function boxSize(weight) {
    return boxScale(weight) * 3.7 + 0.3;
}
function boxOpacity(weight) {
    return 1 - boxScale(weight) / 2;
}
var sizeText = [
    'помещается\nна ладони',
    'маленькая коробка\n(как утюг)',
    'средняя коробка\n(как микроволновка)',
    'большая коробка\n(как стиральная машина)',
    'очень большая коробка\n(как холодильник)',
    'в кузов бобика\nне влезет',
];
function boxSizeText(weight) {
    if (weight < 1) {
        return sizeText[0];
    }
    else if (weight < 6) {
        return sizeText[1];
    }
    else if (weight < 21) {
        return sizeText[2];
    }
    else if (weight < 81) {
        return sizeText[3];
    }
    else if (weight < 250) {
        return sizeText[4];
    }
    else {
        return sizeText[5];
    }
}
export default function SizeVisualization(_a) {
    var value = _a.value;
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "relative w-16" }, { children: [_jsx("div", { className: "left-1.5 bottom-0 w-4 h-4 absolute bg-indigo-600", style: {
                            height: boxSize(value) + "rem",
                            width: boxSize(value) + "rem",
                            opacity: boxOpacity(value),
                        } }, void 0), _jsx(VitruvianMan, { className: "w-auto h-16 text-gray-400 fill-current stroke-current stroke-0" }, void 0)] }), void 0), _jsx("div", __assign({ className: "text-gray-500 text-sm pb-0.5" }, { children: boxSizeText(value)
                    .split('\n')
                    .map(function (str, index) { return (_jsx("p", { children: str }, index)); }) }), void 0)] }, void 0));
}
