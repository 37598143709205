import { minShippingDate } from './utilities/date-time-helpers';
export var transportingTaxName = 'Транспортный сбор';
export var usdCurrencyCode = 'USD';
export var defaultUsdRate = {
    name: 'USD',
    values: 432,
};
export var DeliveryAreas = {
    KAZAKHSTAN: '0',
    ABROAD: '1',
};
export var PalletTypes = [
    { length: 80, width: 60 },
    { length: 120, width: 80 },
    { length: 120, width: 100 },
    { length: 120, width: 120 },
];
export var Services = {
    ECONOMY: 1,
    EXPRESS: 2,
    INTERNATIONAL: 4,
    IP_LETTER: 16,
    IP_PAK: 18,
    IP_OTHER: 19,
    IP_FREIGHT: 20,
    FEDEX_10_KG_BOX: 17,
    FEDEX_25_KG_BOX: 21,
    TNT_EXPRESS: 22,
    TNT_ECONOMY: 23,
};
export var ShipmentTypes = {
    PACKAGE: 3,
    DOCUMENT: 1,
    PALLET: 5,
};
export var ServiceTypes = {
    NONE: 'none',
    BOX: 'box',
    DOCS: 'docs',
    FREIGHT: 'freight',
    SERVICE: 'service',
};
export var PalletWeights = [300, 500, 750, 1000];
export var TimeOptions = {
    MORNING: { from: '9:00', to: '12:00', value: 'morning' },
    DAY: { from: '12:00', to: '16:00', value: 'day' },
};
export var noPackaging = {
    id: -1,
    name: 'Без упаковки',
    type: 'none',
    cost: 0,
    externalID: -1,
};
export var emptyCalculatorRequest = {
    date: minShippingDate(),
    from: 0,
    to: 0,
    type: 0,
    weight: 0,
};
export var emptyOrderRequest = {
    additional_services: [],
    apartment: '',
    building_number: '',
    comment: '',
    company: false,
    date: minShippingDate(),
    floor: '',
    from: 0,
    from_time: '',
    name: '',
    phone_number: '',
    quantity: 0,
    service: '',
    street: '',
    to: 0,
    to_time: '',
    type: 0,
    weight: 0,
};
