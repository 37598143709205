import { jsx as _jsx } from "react/jsx-runtime";
import { ServiceTypes, ShipmentTypes } from '../constants';
import { default as NoPackaging } from '../icons/no-packaging.svg';
import { default as BagPackaging } from '../icons/bag-packaging.svg';
import { default as BoxPackaging } from '../icons/box-packaging.svg';
import { default as SecurePack } from '../icons/securepack.svg';
import { default as PalletPackaging } from '../icons/pallet-packaging.svg';
export var shipmentTypeName = function (type) {
    switch (type) {
        case ShipmentTypes.PACKAGE:
            return 'Посылки и грузы';
        case ShipmentTypes.DOCUMENT:
            return 'Документы';
        case ShipmentTypes.PALLET:
            return 'Палеты';
        default:
            return 'Неизвестно';
    }
};
export var packagingIcon = function (item) {
    switch (item.type) {
        case ServiceTypes.NONE:
            return _jsx(NoPackaging, {}, void 0);
        case ServiceTypes.BOX:
            if (item.name === 'Мешок')
                return _jsx(BagPackaging, {}, void 0);
            return _jsx(BoxPackaging, {}, void 0);
        case ServiceTypes.DOCS:
            return _jsx(SecurePack, {}, void 0);
        case ServiceTypes.FREIGHT:
            return _jsx(PalletPackaging, {}, void 0);
        default:
            return _jsx(BoxPackaging, {}, void 0);
    }
};
export var packagingDimensions = function (item) {
    return item && item.l
        ? item.w
            ? item.h
                ? item.l + "\u2A2F" + item.w + "\u2A2F" + item.h + " \u0441\u043C."
                : item.l + "\u2A2F" + item.w + " \u0441\u043C."
            : item.l + " \u0441\u043C. \u0432 \u0434\u043B\u0438\u043D\u0443"
        : null;
};
