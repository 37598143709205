var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export default function FormField(props) {
    return (_jsxs("div", __assign({ className: props.className }, { children: [_jsxs("label", __assign({ htmlFor: props.name, className: "block text-sm font-medium text-gray-700" }, { children: [props.label, ' ', props.required ? _jsx("span", __assign({ className: "text-red-700" }, { children: "*" }), void 0) : null] }), void 0), props.children] }), void 0));
}
