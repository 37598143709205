var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { SliderHandle, SliderInput, SliderRange, SliderTrack, } from '@reach/slider';
function OptionsSlider(props) {
    return (_jsxs(_Fragment, { children: [_jsx(SliderInput, __assign({ min: 0, max: props.options.length - 1, value: props.value, onChange: props.onChange, className: props.className }, { children: _jsxs(SliderTrack, __assign({ className: "group bg-gray-200 rounded-full cursor-pointer" }, { children: [_jsx(SliderRange, { className: "bg-indigo-600" }, void 0), _jsx(SliderHandle, { className: "w-5 h-5 shadow rounded-full bg-white border border-indigo-600 cursor-pointer\n                                   transition-border ease-custom duration-100 focus:outline-none\n                                   group-active:border-4" }, void 0)] }), void 0) }), void 0), _jsx("div", __assign({ className: "mt-3 flex justify-between select-none text-gray-600 sm:text-sm" }, { children: props.options.map(function (option, index) { return (_jsx("span", __assign({ className: "cursor-pointer " + (index === props.value ? 'font-semibold' : ''), onClick: function () { return props.onChange && props.onChange(index); } }, { children: option }), index)); }) }), void 0)] }, void 0));
}
export default OptionsSlider;
