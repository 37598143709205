var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
function RadioGroup(props) {
    return (_jsxs("fieldset", __assign({ className: "space-y-4 " + props.className }, { children: [(props.legend || props.description) && (_jsxs("div", { children: [props.legend && (_jsx("legend", __assign({ className: "text-base font-medium text-gray-900" }, { children: props.legend }), void 0)), props.description && (_jsx("p", __assign({ className: "text-sm text-gray-500" }, { children: props.description }), void 0))] }, void 0)), props.items &&
                props.items.map(function (item, index) { return (_jsxs("div", __assign({ className: "flex items-center" }, { children: [_jsx("input", { id: props.name + "_" + item.value, type: "radio", value: item.value, name: props.name, checked: item.value === props.value, onChange: props.onChange, className: "focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" }, void 0), _jsx("label", __assign({ htmlFor: props.name + "_" + item.value, className: "ml-3 block text-sm font-medium text-gray-700" }, { children: item.label }), void 0)] }), index)); })] }), void 0));
}
export default RadioGroup;
