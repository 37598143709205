var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import OutlineTextInput from './OutlineTextInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
function NumberInput(props) {
    return (_jsxs("div", __assign({ className: props.wrapperClassName + " flex items-stretch space-x-2" }, { children: [_jsxs("div", __assign({ className: "flex-auto flex flex-row-reverse space-x-2 space-x-reverse md:flex-row md:space-x-2" }, { children: [_jsx("button", __assign({ className: "px-3 rounded-md select-none " + (props.min && props.value <= props.min
                            ? 'bg-gray-100 text-gray-400 cursor-default'
                            : 'bg-indigo-600 hover:bg-indigo-700 text-white'), onClick: function () {
                            if (props.min && props.value === props.min)
                                return;
                            props.onChange(props.value - 1);
                        } }, { children: _jsx(FontAwesomeIcon, { icon: faMinus }, void 0) }), void 0), _jsx(OutlineTextInput, __assign({}, props, { type: "text", onChange: function (event) {
                            return props.onChange(parseInt(event.target.value) || 0);
                        }, onBlur: function () {
                            if (props.min && props.value < props.min)
                                props.onChange(props.min);
                            if (props.max && props.value > props.max)
                                props.onChange(props.max);
                        }, marginTop: 0 }), void 0)] }), void 0), _jsx("button", __assign({ className: "px-3 rounded-md select-none " + (props.max && props.value >= props.max
                    ? 'bg-gray-100 text-gray-400 cursor-default'
                    : 'bg-indigo-600 hover:bg-indigo-700 text-white'), onClick: function () {
                    if (props.max && props.value === props.max)
                        return;
                    props.onChange(props.value + 1);
                } }, { children: _jsx(FontAwesomeIcon, { icon: faPlus }, void 0) }), void 0)] }), void 0));
}
export default NumberInput;
