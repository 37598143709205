var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Skeleton from 'react-loading-skeleton';
import React, { useCallback, useRef, useEffect, useState, } from 'react';
import useOutsideClickHandler from '../utilities/outside-click-handler';
var Autocomplete = function (_a) {
    var isPresented = _a.isPresented, setIsPresented = _a.setIsPresented, restrictive = _a.restrictive, input = _a.input, onFocus = _a.onFocus, onClick = _a.onClick, onClickOutside = _a.onClickOutside, onChange = _a.onChange, onSelect = _a.onSelect, listItemBuilder = _a.listItemBuilder, fetchItems = _a.fetchItems, fetchAllItems = _a.fetchAllItems, parseResponse = _a.parseResponse, value = _a.value, itemText = _a.itemText, textToItem = _a.textToItem;
    var parseDefault = function (res, handleError) { return __awaiter(void 0, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!(res.status === 200)) return [3 /*break*/, 2];
                    return [4 /*yield*/, res.json()];
                case 1: return [2 /*return*/, _b.sent()];
                case 2:
                    if (!(res.status < 500)) return [3 /*break*/, 4];
                    console.log(res);
                    _a = handleError;
                    return [4 /*yield*/, res.json()];
                case 3:
                    _a.apply(void 0, [(_b.sent())['error']]);
                    return [3 /*break*/, 5];
                case 4:
                    console.log(res);
                    handleError('Unknown error');
                    _b.label = 5;
                case 5: return [2 /*return*/];
            }
        });
    }); };
    // State variables
    var _b = useState([]), items = _b[0], setItems = _b[1];
    var _c = useState([]), suggestions = _c[0], setSuggestions = _c[1];
    var _d = useState(undefined), loadingError = _d[0], setLoadingError = _d[1];
    var _e = useState(false), isLoaded = _e[0], setIsLoaded = _e[1];
    var _f = useState(0), activeSuggestion = _f[0], setActiveSuggestion = _f[1];
    var _g = useState(value), oldValue = _g[0], setOldValue = _g[1];
    // Refs
    var autocompleteRef = useRef(null);
    var requestTimeout = useRef(null);
    // Callbacks - Input form
    // Handle click on the input
    var onInputClick = function () {
        setIsPresented(true);
        onClick && onClick();
    };
    var onInputFocus = function () {
        setIsPresented(true);
        onFocus && onFocus();
    };
    // Handle value change
    var onInputChange = useCallback(function (event) {
        setIsPresented(true);
        onChange && onChange(event);
    }, [onChange, setIsPresented]);
    // Handle keyboard controls
    var onKeyDown = useCallback(function (event) {
        var keyCode = event.keyCode;
        // Tab key
        if (keyCode === 9) {
            setIsPresented(false);
        }
        // Enter key
        else if (keyCode === 13) {
            if (activeSuggestion === -1)
                return;
            event.preventDefault();
            setIsPresented(false);
            setActiveSuggestion(0);
            onSelect(suggestions[activeSuggestion]);
        }
        // Up arrow key
        else if (keyCode === 38) {
            if (activeSuggestion === 0)
                return;
            event.preventDefault();
            setActiveSuggestion(activeSuggestion - 1);
        }
        // Down arrow key
        else if (keyCode === 40) {
            if (activeSuggestion - 1 === suggestions.length)
                return;
            event.preventDefault();
            setActiveSuggestion(activeSuggestion + 1);
        }
    }, [activeSuggestion, onSelect, setIsPresented, suggestions]);
    var inputBlock = React.cloneElement(input, {
        value: value,
        onFocus: onInputFocus,
        onClick: onInputClick,
        onChange: onInputChange,
        onKeyDown: onKeyDown,
    });
    // Callbacks - Suggestions list
    // Handle click outside
    var onClickOutsideFunc = onClickOutside || (function () { return setIsPresented(false); });
    var onClickOutsideCallback = useCallback(onClickOutsideFunc, [
        onClickOutsideFunc,
    ]);
    useOutsideClickHandler(autocompleteRef, onClickOutsideCallback);
    // Effects
    // Load all items if there is such a function
    useEffect(function () {
        fetchAllItems &&
            fetchAllItems()
                .then(function (res) {
                var parse = parseResponse || parseDefault;
                parse(res, setLoadingError).then(function (items) {
                    if (!(items instanceof Array))
                        return;
                    setItems(items);
                    setSuggestions(items);
                });
                setIsLoaded(true);
            })
                .catch(function (e) {
                console.log(e);
                setLoadingError('An exception has occurred');
            });
    }, [fetchAllItems]);
    // Handle autocompletion
    useEffect(function () {
        // Do not execute if the value hasn't changed
        if (value === oldValue)
            return;
        setOldValue(value);
        // Autocompletion of prefetched values
        if (fetchAllItems && isLoaded && (!fetchItems || value.length <= 1)) {
            setSuggestions(items.filter(function (item) {
                return itemText(item).toLowerCase().indexOf(value.toLowerCase()) > -1;
            }));
        }
        // Autocompletion with API requests
        if (fetchItems && value.length > 1) {
            // Clear last request timeout if user types faster than requests are sent
            if (requestTimeout.current)
                clearTimeout(requestTimeout.current);
            // Set a 200ms timeout for the request to be sent
            requestTimeout.current = window.setTimeout(function () {
                fetchItems(value)
                    .then(function (res) {
                    var parse = parseResponse || parseDefault;
                    parse(res, setLoadingError).then(function (suggestions) {
                        if (!(suggestions instanceof Array))
                            return;
                        if (!restrictive && textToItem)
                            suggestions.push(textToItem(value));
                        setSuggestions(suggestions);
                    });
                    setIsLoaded(true);
                })
                    .catch(function (e) {
                    console.log(e);
                    setLoadingError('An exception has occurred');
                });
            }, 200);
        }
    }, [
        fetchAllItems,
        fetchItems,
        isLoaded,
        itemText,
        items,
        oldValue,
        parseResponse,
        restrictive,
        textToItem,
        value,
    ]);
    // Check whether suggestions array is empty
    useEffect(function () {
        if (activeSuggestion >= suggestions.length)
            setActiveSuggestion(suggestions.length - 1);
        if (activeSuggestion === -1 && suggestions.length > 0)
            setActiveSuggestion(0);
    }, [activeSuggestion, suggestions]);
    return (_jsxs("div", __assign({ className: "relative" }, { children: [inputBlock, isPresented && (_jsx("div", __assign({ ref: autocompleteRef, className: "mt-1 w-full max-h-72 z-20 absolute overflow-y-auto divide-y border border-gray-200 bg-white shadow-lg rounded-md" }, { children: loadingError ? (_jsx("div", __assign({ className: "px-3 py-2 w-full" }, { children: loadingError }), void 0)) : isLoaded ? (suggestions.length > 0 || !restrictive ? (suggestions.map(function (item, index) { return (_jsx("div", __assign({ onClick: function () {
                        onSelect(item);
                        setIsPresented(false);
                    }, className: "px-3 py-2 w-full flex items-center sm:text-sm cursor-pointer " + (index === activeSuggestion ? 'bg-gray-100' : '') + " hover:bg-indigo-600 hover:text-white" }, { children: listItemBuilder(item) }), index)); })) : (_jsxs("div", __assign({ className: "px-3 py-2 w-full sm:text-sm text-gray-400" }, { children: ["\u041D\u0438\u0447\u0435\u0433\u043E \u043D\u0435 \u043D\u0430\u0439\u0434\u0435\u043D\u043E \u043F\u043E \u0437\u0430\u043F\u0440\u043E\u0441\u0443", ' ', _jsxs("span", __assign({ className: "font-bold" }, { children: ["\"", value, "\""] }), void 0)] }), void 0))) : (_jsx(_Fragment, { children: _jsx("div", __assign({ className: "px-3 py-2 w-full sm:text-sm" }, { children: _jsx(Skeleton, {}, void 0) }), void 0) }, void 0)) }), void 0))] }), void 0));
};
export default Autocomplete;
