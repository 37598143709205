var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SliderHandle, SliderInput, SliderRange, SliderTrack, } from '@reach/slider';
function Slider(props) {
    return (_jsx("div", { children: _jsx(SliderInput, __assign({ min: props.min, max: props.max, step: props.step, value: props.value, onChange: props.onChange, className: props.className }, { children: _jsxs(SliderTrack, __assign({ className: "group bg-gray-200 rounded-full cursor-pointer" }, { children: [_jsx(SliderRange, { className: "bg-indigo-600" }, void 0), _jsx(SliderHandle, { className: "z-0 w-5 h-5 shadow rounded-full bg-white border border-indigo-600 cursor-pointer\n                                   transition-border ease-custom duration-100 focus:outline-none\n                                   group-active:border-4" }, void 0)] }), void 0) }), void 0) }, void 0));
}
export default Slider;
