var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
function Checkbox(props) {
    return (_jsxs("div", __assign({ className: "flex items-center " + props.className }, { children: [_jsx("input", __assign({}, props, { id: props.name, type: "checkbox", className: "rounded focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" }), void 0), _jsx("label", __assign({ htmlFor: props.name, className: "ml-3 block text-sm text-gray-700" }, { children: props.label }), void 0)] }), void 0));
}
export default Checkbox;
