import { useEffect } from 'react';
var useOutsideClickHandler = function (insideRef, callback) {
    return useEffect(function () {
        if (callback) {
            var handleClickOutside_1 = function (event) {
                if (insideRef.current &&
                    event.target &&
                    !insideRef.current.contains(event.target)) {
                    callback();
                }
            };
            document.addEventListener('mousedown', handleClickOutside_1);
            return function () {
                document.removeEventListener('mousedown', handleClickOutside_1);
            };
        }
    }, [insideRef, callback]);
};
export default useOutsideClickHandler;
