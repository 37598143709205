var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import './App.sass';
import Modal from "react-modal";
import { registerLocale } from "react-datepicker";
import { SkeletonTheme } from "react-loading-skeleton";
import Calculator from "./components/Calculator";
import ru from "date-fns/locale/ru";
registerLocale('ru', ru);
Modal.setAppElement('#root');
function App() {
    return (_jsx("div", __assign({ className: "py-10 min-h-screen w-screen h-full" }, { children: _jsx(SkeletonTheme, __assign({ color: "#ECEEF1", highlightColor: "#F9FAFB" }, { children: _jsx("div", __assign({ className: "container lg:max-w-3xl mx-auto p-4" }, { children: _jsx(Calculator, {}, void 0) }), void 0) }), void 0) }), void 0));
}
export default App;
