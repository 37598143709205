var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
export default function Button(props) {
    var active = props.active != null ? props.active : true;
    return (_jsx("button", __assign({ title: props.title, className: "py-2 px-4 w-full rounded-md sm:w-auto transition " + (active
            ? 'text-white bg-indigo-600 hover:bg-indigo-700 shadow'
            : 'bg-gray-200 text-gray-400 cursor-default') + " " + props.className, onClick: function (event) { return active && props.onClick && props.onClick(event); } }, { children: props.children }), void 0));
}
