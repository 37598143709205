import { addDays, getDay, getHours } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
export var timeInKazakhstan = function () {
    return utcToZonedTime(new Date(), 'Asia/Almaty');
};
export var minShippingDate = function () {
    var current = timeInKazakhstan();
    var day = getDay(current);
    var hours = getHours(current);
    if (day === 5 && hours >= 12)
        return addDays(current, 3);
    if (day === 6)
        return addDays(current, 2);
    if (day === 0 || hours >= 12)
        return addDays(current, 1);
    return current;
};
export var isWeekday = function (date) {
    var day = getDay(date);
    return day !== 0 && day !== 6;
};
