var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
function InputGroup(props) {
    var className = "flex rounded-md shadow-sm " + props.className + " ";
    if (props.breakpoint)
        className += "flex-col " + props.breakpoint + ":flex-row";
    else
        className += 'flex-row';
    if (props.children == null)
        return _jsx("div", { className: className }, void 0);
    var children;
    if (props.children.length === 1)
        children = props.children.map(function (child, index) {
            return React.cloneElement(child, {
                key: index,
                className: 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 rounded-md',
            });
        });
    else
        children = props.children.map(function (child, index, children) {
            var inputClassName = 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 ';
            if (index === 0) {
                inputClassName += props.breakpoint
                    ? "rounded-t-md " + props.breakpoint + ":rounded-r-none " + props.breakpoint + ":rounded-l-md "
                    : 'rounded-l-md ';
            }
            else {
                inputClassName += props.breakpoint
                    ? "border-t-transparent " + props.breakpoint + ":border-gray-300 " + props.breakpoint + ":border-l-transparent "
                    : 'border-l-transparent ';
            }
            if (index === children.length - 1) {
                inputClassName += props.breakpoint
                    ? "rounded-b-md " + props.breakpoint + ":rounded-l-none " + props.breakpoint + ":rounded-r-md "
                    : 'rounded-r-md ';
            }
            return React.cloneElement(child, {
                key: index,
                className: inputClassName,
                marginTop: 0,
            });
        });
    return _jsx("div", __assign({ className: className }, { children: children }), void 0);
}
export default InputGroup;
