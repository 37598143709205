var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { default as PackageEconomy } from '../icons/package-economy.svg';
import { default as PackageExpress } from '../icons/package-express.svg';
import { default as PackageInternational } from '../icons/package-international.svg';
import { default as DocumentEconomy } from '../icons/document-economy.svg';
import { default as DocumentExpress } from '../icons/document-express.svg';
import { default as DocumentInternational } from '../icons/document-international.svg';
import { default as PalletEconomy } from '../icons/pallet-economy.svg';
import { default as PalletExpress } from '../icons/pallet-express.svg';
import { default as PalletInternational } from '../icons/pallet-international.svg';
import { default as PackageDefault } from '../icons/package-default.svg';
import { Services, ShipmentTypes } from '../constants';
export var DeliveryOption = function (_a) {
    var result = _a.result, active = _a.active, shipmentType = _a.shipmentType, onClick = _a.onClick;
    var desktopDescriptionRef = useRef(null);
    var _b = useState(0), desktopDescriptionHeight = _b[0], setDesktopDescriptionHeight = _b[1];
    var mobileDescriptionRef = useRef(null);
    var _c = useState(0), mobileDescriptionHeight = _c[0], setMobileDescriptionHeight = _c[1];
    var updateHeights = function () {
        desktopDescriptionRef.current &&
            setDesktopDescriptionHeight(desktopDescriptionRef.current.clientHeight);
        mobileDescriptionRef.current &&
            setMobileDescriptionHeight(mobileDescriptionRef.current.clientHeight);
    };
    useEffect(function () {
        active && updateHeights();
    }, [active, result.description]);
    useEffect(function () {
        if (!active)
            return;
        window.addEventListener('resize', updateHeights);
        return function () { return window.removeEventListener('resize', updateHeights); };
    }, [active]);
    var className = active
        ? 'border-indigo-500 text-indigo-700 bg-purple-50'
        : 'border-gray-300 text-gray-800';
    var deliveryTerms = result.mindeliverydays &&
        result.maxdeliverydays &&
        result.mindeliverydays + "-" + result.maxdeliverydays + " \u0434\u043D\u0435\u0439";
    var resultIcon = useCallback(function (service) {
        switch (shipmentType) {
            case ShipmentTypes.PACKAGE:
                switch (service) {
                    case Services.ECONOMY:
                        return _jsx(PackageEconomy, {}, void 0);
                    case Services.EXPRESS:
                        return _jsx(PackageExpress, {}, void 0);
                    case Services.INTERNATIONAL:
                        return _jsx(PackageInternational, {}, void 0);
                    default:
                        return _jsx(PackageInternational, {}, void 0);
                }
            case ShipmentTypes.DOCUMENT:
                switch (service) {
                    case Services.ECONOMY:
                        return _jsx(DocumentEconomy, {}, void 0);
                    case Services.EXPRESS:
                        return _jsx(DocumentExpress, {}, void 0);
                    case Services.INTERNATIONAL:
                        return _jsx(DocumentInternational, {}, void 0);
                    default:
                        return _jsx(DocumentInternational, {}, void 0);
                }
            case ShipmentTypes.PALLET:
                switch (service) {
                    case Services.ECONOMY:
                        return _jsx(PalletEconomy, {}, void 0);
                    case Services.EXPRESS:
                        return _jsx(PalletExpress, {}, void 0);
                    case Services.INTERNATIONAL:
                        return _jsx(PalletInternational, {}, void 0);
                    default:
                        return _jsx(PalletInternational, {}, void 0);
                }
            default:
                return _jsx(PackageDefault, {}, void 0);
        }
    }, [shipmentType]);
    var icon = result.service
        ? React.cloneElement(resultIcon(result.service), {
            className: 'w-full h-full fill-current',
        })
        : null;
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "py-5 px-6 hidden sm:flex space-x-5 border rounded-md select-none cursor-pointer sm:text-sm " + className, onClick: onClick }, { children: [_jsx("div", __assign({ className: "w-12 h-12 flex-shrink-0 mt-1" }, { children: icon || _jsx(Skeleton, { width: "3rem", className: "absolute h-12" }, void 0) }), void 0), _jsxs("div", __assign({ className: "flex-auto" }, { children: [_jsx("h4", __assign({ className: "text-xl font-medium" }, { children: result.service_name || _jsx(Skeleton, { width: 100 }, void 0) }), void 0), result.description && (_jsx("div", __assign({ style: {
                                    height: (active ? desktopDescriptionHeight + 1 : 0) + "px",
                                }, className: "transition-height duration-500 overflow-y-hidden" }, { children: _jsx("p", __assign({ ref: desktopDescriptionRef, className: "pt-2 pb-1 opacity-70" }, { children: result.description }), void 0) }), void 0)), _jsxs("p", __assign({ className: "mt-1" }, { children: ["\u0421\u0440\u043E\u043A \u0434\u043E\u0441\u0442\u0430\u0432\u043A\u0438: ", deliveryTerms || _jsx(Skeleton, { width: 70 }, void 0)] }), void 0)] }), void 0), _jsx("div", __assign({ className: "flex-shrink-0 flex items-center transition-height duration-500 " + (active && result.description ? 'h-6' : 'h-12') }, { children: _jsxs("span", __assign({ className: "text-xl" }, { children: [result.price ? Math.round(result.price) : _jsx(Skeleton, { width: 50 }, void 0), ' ', "\u20B8"] }), void 0) }), void 0)] }), void 0), _jsxs("div", __assign({ className: "p-6 sm:hidden space-y-4 border rounded-md select-none cursor-pointer " + className, onClick: onClick }, { children: [_jsxs("div", __assign({ className: "flex items-center justify-between" }, { children: [_jsx("div", __assign({ className: "w-14 h-14" }, { children: icon || _jsx(Skeleton, { width: "3.5rem", className: "absolute h-14" }, void 0) }), void 0), _jsxs("span", __assign({ className: "text-2xl -mt-2" }, { children: [result.price ? Math.round(result.price) : _jsx(Skeleton, { width: 50 }, void 0), ' ', "\u20B8"] }), void 0)] }), void 0), _jsxs("div", __assign({ className: "flex-auto" }, { children: [_jsx("h4", __assign({ className: "text-xl font-medium" }, { children: result.service_name || _jsx(Skeleton, { width: 100 }, void 0) }), void 0), result.description && (_jsx("div", __assign({ style: {
                                    height: (active ? mobileDescriptionHeight + 1 : 0) + "px",
                                }, className: "transition-height duration-500 overflow-y-hidden" }, { children: _jsx("p", __assign({ ref: mobileDescriptionRef, className: "pt-2 pb-1 opacity-70" }, { children: result.description }), void 0) }), void 0)), _jsxs("p", __assign({ className: "mt-1" }, { children: ["\u0421\u0440\u043E\u043A \u0434\u043E\u0441\u0442\u0430\u0432\u043A\u0438: ", deliveryTerms || _jsx(Skeleton, { width: 70 }, void 0)] }), void 0)] }), void 0)] }), void 0)] }, void 0));
};
