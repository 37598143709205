var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
function FormSection(props) {
    return (_jsx("div", __assign({ className: "my-5 sm:my-0" }, { children: _jsxs("div", __assign({ className: "" }, { children: [_jsx("div", __assign({ className: "" }, { children: _jsxs("div", __assign({ className: "px-4 sm:px-0" }, { children: [_jsx("h3", __assign({ className: "text-lg font-medium leading-6 text-gray-900" }, { children: props.title }), void 0), props.description && (_jsx("span", __assign({ className: "mt-1 text-sm text-gray-600" }, { children: props.description.split('\n').map(function (str, index) { return (_jsx("p", { children: str }, index)); }) }), void 0))] }), void 0) }), void 0), _jsx("div", __assign({ className: "mt-5" }, { children: props.plain ? (props.children) : (_jsx("div", __assign({ className: "shadow bg-white sm:rounded-md" }, { children: _jsx("div", __assign({ className: "px-4 py-5 sm:p-6 " + props.className }, { children: props.children }), void 0) }), void 0)) }), void 0)] }), void 0) }), void 0));
}
export default FormSection;
