var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
function TypeItem(props) {
    var leading = props.leading
        ? React.cloneElement(props.leading, {
            className: "w-auto h-5 fill-current " + (props.active ? 'text-indigo-700' : ''),
        })
        : null;
    var className = 'py-2 px-4 flex items-center space-x-3 focus:outline-none ';
    className += props.breakpoint
        ? "w-full " + props.breakpoint + ":flex-auto " + props.breakpoint + ":w-auto " + props.breakpoint + ":justify-center "
        : 'flex-auto justify-center ';
    if (props.active)
        className += 'text-gray-800 bg-white shadow';
    else
        className += 'text-gray-500';
    return (_jsxs("button", __assign({ onClick: props.onClick, className: className }, { children: [leading, _jsx("span", { children: props.name }, void 0)] }), void 0));
}
function TypeSelector(props) {
    var className = 'w-full mt-2 overflow-hidden select-none shadow-sm divide-gray-300 border border-gray-300 bg-gray-100 rounded-md ';
    className += props.breakpoint
        ? "divide-y " + props.breakpoint + ":flex " + props.breakpoint + ":divide-y-0 " + props.breakpoint + ":divide-x"
        : 'flex divide-x flex-wrap';
    return (_jsx("div", __assign({ className: className }, { children: props.types.map(function (type) { return (_jsx(TypeItem, { name: type.name, leading: type.icon, active: type.id === props.value, breakpoint: props.breakpoint, onClick: function () { return props.onChange && props.onChange(type.id); } }, type.id)); }) }), void 0));
}
export { TypeItem };
export default TypeSelector;
