var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
function TextInput(props) {
    var leading = props.leading ? (_jsx("div", __assign({ className: "absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none" }, { children: _jsx("span", __assign({ className: "text-gray-500 sm:text-sm" }, { children: props.leading }), void 0) }), void 0)) : null;
    var trailing = props.trailing ? (_jsx("div", __assign({ className: "absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none" }, { children: _jsx("span", __assign({ className: "text-gray-500 sm:text-sm" }, { children: props.trailing }), void 0) }), void 0)) : null;
    var className = "w-full block sm:text-sm " + props.className + " ";
    if (props.leading) {
        var leadingWidth = props.leadingWidth || props.leading.length;
        className += "pl-" + (leadingWidth + 6) + " ";
    }
    if (props.trailing) {
        var trailingWidth = props.trailingWidth || props.trailing.length;
        className += "pr-" + (trailingWidth + 6) + " ";
    }
    var marginTop = props.marginTop != null ? props.marginTop : 1;
    var width = props.width || 'full';
    var wrapperClassName = "mt-" + marginTop + " w-" + width + " relative focus-within:z-10 ";
    return (_jsxs("div", __assign({ className: wrapperClassName }, { children: [leading, trailing, _jsx("input", { id: props.name, type: props.type, name: props.name, min: props.min, max: props.max, step: props.step, value: props.value, onBlur: props.onBlur, onFocus: props.onFocus, onClick: props.onClick, onChange: props.onChange, onKeyDown: props.onKeyDown, autoComplete: props.autoComplete, placeholder: props.placeholder, className: className }, void 0)] }), void 0));
}
export default TextInput;
